<template>
	<div>
		<van-nav-bar
			:title="title"
			left-text="返回"
			left-arrow
            fixed
			@click-left="onClickLeft"
		/>
        
        <h-40></h-40>

		<div v-for="(item,index) in imgText" :key="'t_'+index">
            <img-text-list-max :item="item" :index="index"></img-text-list-max>
		</div>
        
        <div class="m-t-30" style="padding: 0 20px;">
            <van-pagination v-model="page" :page-count="pageTotal" :total-items="pageNum"  @change="pageEvent">
                <template #prev-text>
                    <van-icon name="arrow-left" />
                </template>
                <template #next-text>
                    <van-icon name="arrow" />
                </template>
                <template #page="{ text }">{{ text }}</template>
            </van-pagination>
        </div>
    
		<div v-if="fontList.length < 1 && imgText.length < 1">
            <van-empty :description="$common.noDataText" />
        </div>
		
		<common-footer divider></common-footer>
	</div>
</template>

<script>
	import imgTextListMax from '../../components/list/img-text-list-max.vue'
	
	export default {
		components:{
			imgTextListMax
		},
		data() {
			return {
                title:'栏目列表',
                slug:'',
				list: [],
				loading: true,
				finished: true,
				fontList:[],
				imgText:[],
                page:1,
                pageNum:'',
                pageTotal:''
			}
		},
        mounted() {
            if(this.$route.query.slug){
                this.slug = this.$route.query.slug
                this.getList(this.slug)
            }
        },
		methods: {
            getList(slug){
                this.$api.article.getCategoriesArticleList(slug,this.page).then((res) => {
                    if(res.data.length > 0) this.title = res.data[0].cateogry.name
                    // 设置title
                    document.title = this.title
                    this.imgText = res.data
                    
                    this.pageTotal = this.$computed.page(res.meta.total, res.meta.per_page)
                    this.pageNum = res.meta.total
                })
            },
            pageEvent(page){
                this.page = page
                this.getList(this.slug)
                // 回到顶部
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            },
			onClickLeft() {
				// this.$toast('返回');
				this.$router.go(-1);
			}
		},
	};
</script>

<style>
	.swipe-view{
		width: 120px;
		height: 120px;
	}
	.swipe-view img{
		width: 110px;
		height: 90px;
	}
</style>
