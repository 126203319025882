<template>
	<div class="flex m-10 box-shadow padding-b-10 padding-l-5 padding-r-5" @click="openDetail(item.id)">
		<img v-if="item.thumb" :src="item.thumb" class="br-5 m-r-10" width="150" height="100" alt="">
		<div class="flex1">
			<div class="text-ellipsiss w-l-c-3">{{item.title}}</div>
            <div v-if="item.zhiding || item.tuijian || item.jinghua" class="flex flex-w-n flex-ai-c label-item m-t-5 m-b-5">
                <div v-if="item.zhiding" class="flex flex-ai-c m-r-10 zhiding">
                    <van-icon name="back-top" /><span>置顶</span>
                </div>
                <div v-if="item.tuijian" class="flex flex-ai-c m-r-10 tuijian">
                    <van-icon name="good-job-o" /><span>推荐</span>
                </div>
                <div v-if="item.jinghua" class="flex flex-ai-c m-r-10 jinghua">
                    <van-icon name="label-o" /><span>精华</span>
                </div>
            </div>
            <div class="flex flex-ai-c">
                <div v-if="lanmu">
                    <van-tag plain type="success" @click.stop="openLanmu">{{lanmu}}</van-tag>
                </div>
                <div class="grey flex flex-ai-c font-13 m-l-10">
                    {{item.created_at}}
                </div>
            </div>
		</div>
	</div>
</template>

<script>
	export default {
		props:{
			item:{
				type:Object
			},
			index:{
				type:Number
			},
            lanmu:{
                type:String
            },
            slug:{
                type:String
            }
		},
        methods:{
            openDetail(id){
                if(!this.item.link){
                    this.$router.push({
                        path:'/article/details',
                        query:{
                            id:id
                        }
                    })
                }else{
                    window.location.href = this.item.link
                }
            },
            openLanmu(){
                this.$router.push({
                    path:'/article/list',
                    query:{
                        slug:this.slug
                    }
                })
            }
        }
	}
</script>

<style>
    .label-item span{
        font-size: 10px;
        margin-left: 2px;
    }
    .label-item .zhiding{
        color: #FF6600;
    }
    .label-item .tuijian{
        color: #FF0000;
    }
    .label-item .jinghua{
        color: #07c160;
    }
</style>
